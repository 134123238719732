/** @jsxImportSource @emotion/react */                                                                                                                                //include this in all jsx files
import React from 'react';
import {css} from "@emotion/react";
import {theme} from './theme';

const styles = {
  zContainer: css`
        position: relative;
        font-size: 35px;
        font-weight: 900;
        padding: 0 5px 0 8px;
        font-family: "Roboto", sans-serif;
        display: flex;
      @media (min-width: ${theme.breakpoints.sm}px) {
          font-size: 55px;
      }
    `,
  backZ: css`
        color: ${theme.colors.backZ};
        ::after {
            content: 'Z';
            color: ${theme.colors.frontZ};
            transform: translateX(-18px) translateY(-3px);
            position: absolute;
            @media (min-width: ${theme.breakpoints.sm}px) {
                transform: translateX(-30px) translateY(-4px);
            }
        }
    `,
};
interface Props {
  className?: string;
  onClick?: () => void;
}
const ZLogo: React.FC<Props> = ({className, onClick}) => {
  return (
    <div css={styles.zContainer} onClick={onClick} className={className}>
      <div className={'backZ'} css={styles.backZ}>Z</div>
    </div>
  )
}
export default ZLogo;