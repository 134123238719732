export const codeSamples = {
	introCard: `/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react';
import {css} from '@emotion/react';
import Slider from '../Slider';
import ZLogo from '../../ZLogo';

const styles = {
	root: css\`
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
	\`,
	stage: css\`
      perspective: 1000px;
      padding: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
	\`,
	extrusion: css\`
      width: 200px;
      height: 200px;
      position: relative;
      transform-style: preserve-3d;
      transition: transform 1s;
	\`,
	plane: css\`
      position: absolute;
      width: 100%;
      height: 200px;
      border-radius: 20px;
      text-align: center;
      border: solid 1px #4e6468;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      span {
          display: flex;
          flex-direction: row;
          align-items: center;
      }
	\`,
	left: (hRotation: number, vRotation: number) => css\`
      background-color: #304145;
      color: white;
      font-size: 30px;
      transform: rotateY(\${hRotation}deg) rotateX(\${vRotation}deg) translateZ(1px);
	\`,
	right: (hRotation: number, vRotation: number) => css\`
      background-color: #304145;
      color: white;
      font-size: 30px;
      transform: rotateY(\${hRotation}deg) rotateX(\${vRotation}deg) translateZ(0px);
	\`,
};


const IntroCard = () => {
	const [hRotation, setHRotation] = useState(0);
	const vRotation = 0;
	const [showGreeting, setShowGreeting] = useState(false);
	const [showLastGreeting, setShowLastGreeting] = useState(false);
	const [resetMessage, setResetMessage] = useState(false);
	
	const handleChangeHorizontal = (percent: number) => {
		setHRotation(percent * 180);
	};
	
	useEffect(() => {
		const resetCard = hRotation < 90 && resetMessage;
		const firstTransition = hRotation > 150 && !showGreeting && !showLastGreeting && !resetMessage;
		const secondTransition = hRotation < 85 && showGreeting && !showLastGreeting && !resetMessage;
		const lastTransition = hRotation > 150 && showGreeting && showLastGreeting && !resetMessage;
		
		if (resetCard) {
			setShowGreeting(false);
			setShowLastGreeting(false);
			setResetMessage(false);
		} else if (firstTransition) {
			setShowGreeting(true);
		} else if (secondTransition) {
			setShowLastGreeting(true);
		} else if (lastTransition) {
			setResetMessage(true);
		}
	}, [hRotation, showGreeting, showLastGreeting, resetMessage]);
	
	return (
		<div css={styles.root}>
			<div css={styles.stage}>
				<div css={styles.extrusion}>
					<div css={[styles.plane, styles.left(hRotation + 180, -vRotation)]}>
						{!showLastGreeting
							? <span>I'm <ZLogo/>ack.</span>
							: <span>Ok, bye.</span>
						}
					</div>
					<div css={[styles.plane, styles.right(hRotation, vRotation)]}>
						{!showGreeting
						? <span>Hi.</span>
						: <span>Nice to meet you.</span>
						}
					</div>
				</div>
			</div>
			<Slider onChange={handleChangeHorizontal}/>
		</div>
	);
};

export default IntroCard`,
	rotationShapes: `/** @jsxImportSource @emotion/react */
import React, {useState} from 'react';
import {css} from '@emotion/react';
import Slider from '../Slider';
import VerticalSlider from '../VerticalSlider';
import {theme} from '../../theme';

const styles = {
	root: css\`
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
	\`,
	stage: css\`
      perspective: 1000px;
      padding: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 30px;
	\`,
	extrusion: css\`
      width: 200px;
      height: 200px;
      position: relative;
      transform-style: preserve-3d;
      transform: translateZ(-100px);
      transition: transform 1s;
      padding: 50px;
	
	\`,
	plane: css\`
      position: absolute;
      width: 150px;
      height: 150px;
      border-radius: 20px;
	\`,
	left: (hRotation: number, vRotation: number) => css\`
      background-color: \${theme.colors.frontZ};
      transform: rotateY(\${hRotation}deg) rotateX(\${vRotation}deg) translateZ(-50px);
	\`,
	right: (hRotation: number, vRotation: number) => css\`
      background-color: \${theme.colors.backZ};
      transform: rotateY(\${hRotation}deg) rotateX(\${vRotation}deg) translateZ(50px);
	\`,
};


const RotationShapes = () => {
	const [hRotation, setHRotation] = useState(0);
	const [vRotation, setVRotation] = useState(0);
	
	const handleChangeHorizontal = (percent: number) => {
		setHRotation(percent * 360 + 180);
	};
	const handleChangeVertical = (percent: number) => {
		setVRotation(percent * 360 + 180);
	};
	
	return (
		<div css={styles.root}>
			<div css={styles.stage}>
				<div css={styles.extrusion}>
					<div css={[styles.plane, styles.left(hRotation, vRotation)]}/>
					<div css={[styles.plane, styles.right(hRotation, vRotation)]}/>
				</div>
				<VerticalSlider initialPosition={100} onChange={handleChangeVertical}/>
			</div>
			<Slider initialPosition={100} onChange={handleChangeHorizontal}/>
		</div>
	);
};

export default RotationShapes;`,
	slider: `/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';

const DEFAULT_SLIDER_WIDTH = 200

// Define Emotion CSS styles
const styles = {
	sliderRoot: css\`
        position: relative;
        touch-action: none; // Disable default touch behavior
        
        &:active {
            // Add shadow transformation on active
            & > .shadow {
                transform: translateY(-5px) translateX(-12px);
                transition: transform 34ms;
            }
        }
    \`,
	root: css\`
        justify-content: center;
        width: \${DEFAULT_SLIDER_WIDTH}px;
        height: 10px;
        padding: 20px;
        border-radius: 10px;
        touch-action: none; // Prevent default touch behavior
    \`,
	bar: css\`
        height: 6px;
        border-radius: 50px;
        width: \${DEFAULT_SLIDER_WIDTH}px;
        background-color: #85aabf;
        position: relative;
    \`,
	// Dynamic width for status bar (based on slider position)
	status: (location: number) => css\`
        height: 6px;
        border-radius: 50px;
        width: \${location}px;
        background-color: #0e5e8c;
        position: absolute;
        top: 0;
        left: 0;
    \`,
	thumb: (location: number) => css\`
        height: 20px;
        width: 30px;
        border-radius: 100px;
        left: \${location}px;
        background-color: #158ad0;
        position: absolute;
        top: 0;
        touch-action: none; // Disable default touch behavior
        cursor: grab;
        transform: translateY(-10px) translateX(-12px); // Adjust thumb position
        transition: transform 400ms cubic-bezier(.3, .7, .4, 1);
        
        &:hover {
            // Slight lift on hover
            transform: translateY(-12px) translateX(-12px);
            transition: transform 250ms;
        }
        
        &:active {
            // Adjust thumb for active state
            cursor: grabbing;
            transform: translateY(-8px) translateX(-12px);
            transition: transform 34ms;
        }
    \`,
	thumbEdge: (location: number) => css\`
        height: 20px;
        width: 30px;
        border-radius: 100px;
        left: \${location}px;
        background-color: #0e5e8c;
        position: absolute;
        top: 0;
        cursor: grab;
        transform: translateY(-7px) translateX(-12px); // Adjust thumb position
    \`,
	shadow: (location: number) => css\`
        height: 20px;
        width: 30px;
        border-radius: 100px;
        left: \${location}px;
        background: hsl(0deg 0% 0% / 0.25); // Light shadow
        position: absolute;
        will-change: transform;
        top: 0;
        cursor: grab;
        filter: blur(1px);
        transform: translateY(-4px) translateX(-12px); // Adjust shadow position
        transition: transform 400ms cubic-bezier(.3, .7, .4, 1);
    \`,
};
interface SliderProps {
	initialPosition?: number; // Starting position of the slider (optional)
	className?: string; // Optional custom class name for styling
	onChange?: (slidePercent: number) => void; // Callback function when slider moves
}

const Slider: React.FC<SliderProps> = ({ initialPosition = 0, className, onChange }) => {
	const [position, setPosition] = useState(initialPosition); // Current slider position
	const [pressed, setPressed] = useState(false); // State to track if slider is being dragged
	
	// Reference to the slider DOM element
	const sliderRef = useRef<HTMLDivElement | null>(null);
	
	// Function to handle pointer movement
	const onPointerMove = (event: React.PointerEvent<HTMLDivElement>) => {
		if (!pressed || !sliderRef.current) return;
		
		const sliderLeftPosition = sliderRef.current.getBoundingClientRect().left;
		const newPosition = event.clientX - sliderLeftPosition; // Calculate new position
		
		// Update the position if within valid range
		if (newPosition >= 0 && newPosition <= DEFAULT_SLIDER_WIDTH) {
			setPosition(newPosition);
			const newSlidePercent = newPosition / DEFAULT_SLIDER_WIDTH;
			
			// Trigger the onChange callback if provided
			if (onChange) {
				onChange(newSlidePercent);
			}
		}
		
		event.stopPropagation();
		event.preventDefault();
	};
	
	// Function to handle when pointer is released
	const onPointerUp = (event: PointerEvent) => {
		setPressed(false);
		(event.target as HTMLElement).releasePointerCapture(event.pointerId);
		event.stopPropagation();
		event.preventDefault();
	};
	
	// Function to handle pointer press down (starts dragging)
	const onPointerDown = (event: React.PointerEvent) => {
		setPressed(true);
		(event.target as HTMLElement).setPointerCapture(event.pointerId);
		event.stopPropagation();
		event.preventDefault();
	};
	
	// Hook to attach and detach pointer up event listener based on pressed state
	useEffect(() => {
		if (pressed) {
			document.addEventListener('pointerup', onPointerUp);
		} else {
			document.removeEventListener('pointerup', onPointerUp);
		}
		
		// Clean up the event listener when component unmounts or pressed changes
		return () => {
			document.removeEventListener('pointerup', onPointerUp);
		};
	}, [pressed]);
	
	return (
		<div className={className} css={styles.root} onPointerMove={onPointerMove}>
			<div ref={sliderRef} css={styles.sliderRoot}>
				<div css={styles.bar} /> {/* Slider bar */}
				<div css={styles.status(position)} /> {/* Status bar to show progress */}
				<div id="sliderShadow" className="shadow" css={styles.shadow(position)} /> {/* Shadow effect */}
				<div className="thumbEdge" css={styles.thumbEdge(position)} /> {/* Edge of the thumb */}
				<div className="thumb" onPointerDown={onPointerDown} css={styles.thumb(position)} /> {/* Draggable thumb */}
			</div>
		</div>
	);
};
export default Slider;`,
	verticalSlider: `/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';

const DEFAULT_SLIDER_HEIGHT = 200

// Define Emotion CSS styles with proper typing for dynamic styles
const styles = {
	sliderRoot: css\`
        position: relative;
        
        &:active {
            & > .shadow {
                transform: translateX(-8px) translateY(-9px);
                transition: transform 34ms;
            }
        }
    \`,
	root: css\`
        justify-content: center;
        padding: 20px;
        border-radius: 10px;
        height: \${DEFAULT_SLIDER_HEIGHT}px;
        touch-action: none; // Disable default touch behavior
    \`,
	bar: css\`
        width: 6px;
        border-radius: 50px;
        height: \${DEFAULT_SLIDER_HEIGHT}px;
        background-color: #85AABF;
        position: relative;
    \`,
	// Status bar changes dynamically based on the slider position
	status: (location: number) => css\`
        width: 6px;
        border-radius: 50px;
        height: \${location}px;
        background-color: #0E5E8C;
        position: absolute;
        top: 0;
        left: 0;
    \`,
	thumb: (location: number) => css\`
        width: 20px;
        height: 30px;
        border-radius: 100px;
        top: \${location}px;
        background-color: #158AD0;
        position: absolute;
        left: 0;
        touch-action: none; // Disable default touch behavior
        cursor: grab;
        transform: translateX(-8px) translateY(-17px); // Adjust thumb position
        transition: transform 400ms cubic-bezier(.3, .7, .4, 1);
        
        &:hover {
            transform: translateX(-8px) translateY(-19px);
            transition: transform 250ms;
        }
        
        &:active {
            cursor: grabbing;
            transform: translateX(-8px) translateY(-15px);
            transition: transform 34ms;
        }
    \`,
	thumbEdge: (location: number) => css\`
        width: 20px;
        height: 30px;
        border-radius: 100px;
        top: \${location}px;
        background-color: #0E5E8C;
        position: absolute;
        left: 0;
        cursor: grab;
        transform: translateX(-8px) translateY(-12px); // Adjust thumb edge position
    \`,
	shadow: (location: number) => css\`
        width: 20px;
        height: 30px;
        border-radius: 100px;
        top: \${location}px;
        background: hsl(0deg 0% 0% / 0.25);
        position: absolute;
        will-change: transform;
        left: 0;
        cursor: grab;
        filter: blur(1px);
        transform: translateX(-8px) translateY(-8px); // Adjust shadow position
        transition: transform 400ms cubic-bezier(.3, .7, .4, 1);
    \`,
};

// Define prop types using TypeScript interfaces
interface VerticalSliderProps {
	initialPosition?: number; // Optional initial position, default is 100
	className?: string; // Optional custom class name
	onChange?: (slidePercent: number) => void; // Callback function triggered on slide change
}

const VerticalSlider: React.FC<VerticalSliderProps> = ({ initialPosition = 100, className, onChange }) => {
	const [position, setPosition] = useState<number>(initialPosition); // Current position of the slider
	const [pressed, setPressed] = useState<boolean>(false); // Track if the thumb is being dragged
	const [slidePercent, setSlidePercent] = useState<number>(initialPosition / 200); // Slider percentage
	const sliderRef = useRef<HTMLDivElement | null>(null); // Reference to the slider element
	
	// Handle pointer movement
	const onPointerMove = (event: React.PointerEvent<HTMLDivElement>) => {
		if (!pressed || !sliderRef.current) return;
		
		const sliderTopPosition = sliderRef.current.getBoundingClientRect().top;
		const newPosition = event.clientY - sliderTopPosition;
		
		// Check if the new position is within bounds and update accordingly
		if (newPosition >= 0 && newPosition <= DEFAULT_SLIDER_HEIGHT) {
			setPosition(newPosition);
			const newSlidePercent = newPosition / DEFAULT_SLIDER_HEIGHT;
			setSlidePercent(newSlidePercent);
			
			// Trigger the onChange callback if provided
			if (onChange) {
				onChange(newSlidePercent);
			}
		}
		
		event.stopPropagation();
		event.preventDefault();
	};
	
	// Handle pointer release
	const onPointerUp = (event: PointerEvent) => {
		setPressed(false);
		(event.target as HTMLElement).releasePointerCapture(event.pointerId);
		event.stopPropagation();
		event.preventDefault();
	};
	
	// Handle pointer down (start dragging)
	const onPointerDown = (event: React.PointerEvent<HTMLDivElement>) => {
		setPressed(true);
		(event.target as HTMLElement).setPointerCapture(event.pointerId);
		event.stopPropagation();
		event.preventDefault();
	};
	
	// Effect to attach and detach pointer up event listener based on pressed state
	useEffect(() => {
		if (pressed) {
			document.addEventListener('pointerup', onPointerUp);
		} else {
			document.removeEventListener('pointerup', onPointerUp);
		}
		
		// Cleanup event listener when component unmounts or pressed changes
		return () => {
			document.removeEventListener('pointerup', onPointerUp);
		};
	}, [pressed]);
	
	
	return (
		<div className={className} css={styles.root} onPointerMove={onPointerMove}>
			<div ref={sliderRef} css={styles.sliderRoot}>
				<div css={styles.bar} /> {/* Slider bar */}
				<div css={styles.status(position)} /> {/* Status bar */}
				<div id="sliderShadow" className="shadow" css={styles.shadow(position)} /> {/* Shadow effect */}
				<div className="thumbEdge" css={styles.thumbEdge(position)} /> {/* Thumb edge */}
				<div className="thumb" onPointerDown={onPointerDown} css={styles.thumb(position)} /> {/* Thumb (draggable) */}
			</div>
		</div>
	);
};

export default VerticalSlider;`,
	analogButton: `/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

// Define Emotion CSS styles with proper typing for dynamic styles
const styles = {
	wrapper: css\`
position: relative;
padding: 3px 0 0 0;
background: transparent;
border: none;
cursor: pointer;
touch-action: none;

&:hover {
	& > .top {
	transform: translateY(-8px);
	transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
	}
	
	& > .shadow {
	transform: translateY(4px);
	transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
	}
}

&:active {
	& > .top {
	transform: translateY(-2px);
	transition: transform 34ms;
	}
	
	& > .shadow {
	transform: translateY(1px);
	transition: transform 34ms;
	}
}
\`,
	// The top portion of the button, styled dynamically based on the color
	top: (color: string) => css\`
padding: 15px 40px;
position: relative;
top: 0;
left: 0;
border-radius: 12px;
background-color: \${color};
display: block;
font-size: 1.25em;
color: #fff;
font-weight: 700;
transform: translateY(-6px);
will-change: transform;
transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
\`,
	bottom: (color: string) => css\`
width: 100%;
height: 100%;
position: absolute;
background: \${color};
top: 0;
left: 0;
border-radius: 12px;
\`,
	detail: css\`
width: 100%;
height: 100%;
position: absolute;
top: 0;
left: 0;
border-radius: 12px;
background: linear-gradient(
	to left,
	hsla(0, 0%, 0%, 0.5) 0%,
	hsla(0, 0%, 0%, 0.3) 8%,
	hsla(0, 0%, 0%, 0.3) 92%,
	hsla(0, 0%, 0%, 0.5) 100%
);
\`,
	shadow: css\`
width: 100%;
height: 100%;
top: 0;
left: 0;
position: absolute;
transform: translateY(3px);
border-radius: 12px;
background: hsl(0deg 0% 0% / 0.25);
will-change: transform;
filter: blur(1px);
transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
\`,
};

// Utility function to check if a given string is a valid CSS color
const hasColor = (color: string): boolean => {
	const styleTest = new Option().style;
	styleTest.color = color;
	return styleTest.color !== '';
};

// Define prop types using TypeScript interfaces
interface AnalogButtonProps {
color?: string; // Optional color prop, default is "blue" if not provided
onClick?: () => void; // Optional click handler
className?: string; // Optional custom class name
children: React.ReactNode; // The content inside the button (e.g., text or elements)
}

// The AnalogButton component
const AnalogButton: React.FC<AnalogButtonProps> = ({ color = "blue", onClick, className, children }) => {
const buttonColor = hasColor(color) ? color : "blue"; // Fallback to "blue" if color is invalid

return (
	<button className={className} onPointerUp={onClick} css={styles.wrapper}>
		<div className="shadow" css={styles.shadow} /> {/* Shadow effect */}
		<div css={styles.bottom(buttonColor)} /> {/* Bottom part of the button */}
		<div css={styles.detail} /> {/* Additional detail on the button */}
		<div className="top" css={styles.top(buttonColor)}> {/* Top part of the button */}
			<span>{children}</span> {/* Button content (children) */}
		</div>
	</button>
);
};

export default AnalogButton;`,
	lightningStrike: `/** @jsxImportSource @emotion/react */
import React, {useEffect, useRef, useState} from 'react';
import {css} from '@emotion/react';
import {openSimplexNoise} from './openSimplexNoise';
import {theme} from '../theme';

const styles = {
	root: css\`
      justify-content: center;
      align-items: center;
      touch-action: none;
      display: flex;
	\`,
	canvas: css\`
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
	\`,
	noCanvas: css\`
      position: absolute;
      height: 1px;
      width: 1px;
      top: 0;
      left: 0;
	\`,
};

let animationId: number | undefined; // Holds the reference to the animation frame

const getWindowDimensions = () => {
	const {innerWidth: width, innerHeight: height} = window;
	return {
		windowWidth: width,
		windowHeight: height,
	};
};

const useWindowDimensions = () => {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
	
	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}
		
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);
	
	return windowDimensions;
};

// Convert hue value to RGB value
const hueToRgb = (v1: number, v2: number, vh: number) => {
	vh /= 360;
	if (vh < 0) vh++;
	if (vh > 1) vh--;
	if (vh < 1 / 6) return v1 + (v2 - v1) * 6 * vh;
	if (vh < 1 / 2) return v2;
	if (vh < 2 / 3) return v1 + (v2 - v1) * (2 / 3 - vh) * 6;
	return v1;
};

// Convert HSL to RGB for color manipulation
const hslToRgb = (h: number, s: number, l: number) => {
	s /= 100;
	l /= 100;
	
	let r, g, b;
	
	if (s === 0) {
		r = g = b = l * 255;
	} else {
		const v2 = l < 0.5 ? l * (1 + s) : l + s - l * s;
		const v1 = 2 * l - v2;
		r = Math.round(hueToRgb(v1, v2, h + 120) * 255);
		g = Math.round(hueToRgb(v1, v2, h) * 255);
		b = Math.round(hueToRgb(v1, v2, h - 120) * 255);
	}
	return 'rgb(' + r + ', ' + g + ', ' + b + ')';
};

// Function to get curve points for smooth drawing
const getCurvePoints = (pts: number[], tension = 0.5, isClosed = false, numOfSegments = 16) => {
	let _pts = pts.slice(0), // Clone the points array
		res = [], x, y, t1x, t2x, t1y, t2y, c1, c2, c3, c4, st, t;
	
	// Handle closed or open curve by adding points at the start and end
	if (isClosed) {
		_pts.unshift(pts[pts.length - 1], pts[pts.length - 2], pts[pts.length - 1], pts[pts.length - 2]);
		_pts.push(pts[0], pts[1]);
	} else {
		_pts.unshift(pts[1], pts[0]);
		_pts.push(pts[pts.length - 2], pts[pts.length - 1]);
	}
	
	// Loop through the points and calculate the curve segments
	for (let i = 2; i < _pts.length - 4; i += 2) {
		for (t = 0; t <= numOfSegments; t++) {
			t1x = (_pts[i + 2] - _pts[i - 2]) * tension;
			t2x = (_pts[i + 4] - _pts[i]) * tension;
			t1y = (_pts[i + 3] - _pts[i - 1]) * tension;
			t2y = (_pts[i + 5] - _pts[i + 1]) * tension;
			st = t / numOfSegments;
			
			c1 = 2 * st ** 3 - 3 * st ** 2 + 1;
			c2 = -(2 * st ** 3) + 3 * st ** 2;
			c3 = st ** 3 - 2 * st ** 2 + st;
			c4 = st ** 3 - st ** 2;
			
			x = c1 * _pts[i] + c2 * _pts[i + 2] + c3 * t1x + c4 * t2x;
			y = c1 * _pts[i + 1] + c2 * _pts[i + 3] + c3 * t1y + c4 * t2y;
			
			res.push(x, y); // Store the calculated coordinates
		}
	}
	
	return res; // Return the generated curve points
};

// Draw the curve based on calculated points
const drawCurve = (
	ctx: CanvasRenderingContext2D,
	ptsa: number[],
	tension: number,
	isClosed: boolean,
	numOfSegments: number,
	showPoints = false,
) => {
	drawLines(ctx, getCurvePoints(ptsa, tension, isClosed, numOfSegments));
	
	// Optionally draw small rectangles at the points for debugging
	if (showPoints) {
		ctx.stroke();
		ctx.beginPath();
		for (let i = 0; i < ptsa.length - 1; i += 2) {
			ctx.rect(ptsa[i] - 2, ptsa[i + 1] - 2, 4, 4);
		}
	}
};

const drawLines = (ctx: CanvasRenderingContext2D, pts: number[]) => {
	ctx.moveTo(pts[0], pts[1]);
	for (let i = 2; i < pts.length - 1; i += 2) ctx.lineTo(pts[i], pts[i + 1]);
};

// Initialize the noise generator
const noiseSeed = Date.now();
const noise = openSimplexNoise(noiseSeed);

const drawImpact = (context: CanvasRenderingContext2D, pts: number[], flicker: number, color: string) => {
	context.beginPath();
	context.fillStyle = color;
	context.shadowBlur = 10;
	context.shadowColor = color;
	context.arc(pts[pts.length - 2], pts[pts.length - 1], noise.noise2D(flicker, .5) * 10 + 10, 0, 2 * Math.PI);
	context.fill();
	
};

const cancelStrike = (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) => {
	context.clearRect(0, 0, canvas.width, canvas.height);
};

const renderStrike = (
	canvas: HTMLCanvasElement,
	context: CanvasRenderingContext2D,
	windowWidth: number,
	windowHeight: number,
	mouseXY: { x: number, y: number },
	clickedZap: boolean,
) => {
	
	let time = Math.floor(Math.random() * 1000);
	let flicker = 0;
	const amplitude = 50;
	const H = 195;
	const S = 100;
	const L_MAX = 75;
	const L_MIN = 55;
	
	const x0 = mouseXY.x;
	
	const render = () => {
		if (!clickedZap) {
			return;
		}
		
		const colorL = (noise.noise2D(flicker, 0)) * (L_MAX - L_MIN) + (L_MAX + L_MIN) / 2;
		const colorLChild1 = (noise.noise2D(flicker + .30, 0)) * (L_MAX - L_MIN) + (L_MAX + L_MIN) / 2;
		const colorLChild2 = (noise.noise2D(flicker + 45, 0)) * (L_MAX - L_MIN) + (L_MAX + L_MIN) / 2;
		
		const getCoordinates = (time: number, segments: number) => {
			const yStep = mouseXY.y / (segments - 1);
			const pts = [];
			
			pts[0] = x0;
			pts[1] = 0;
			
			for (let i = 1; i < segments; i++) {
				const lowOctaveNoise = noise.noise2D(time + i / 8, 0) * amplitude * 3;
				const highOctaveNoise = noise.noise2D(time + i * 180, 0) * amplitude / 1.5;
				pts[i * 2] = x0 + lowOctaveNoise + highOctaveNoise;
				pts[i * 2 + 1] = i * yStep;
			}
			pts[segments * 2 - 2] = mouseXY.x;
			pts[segments * 2 - 1] = mouseXY.y;
			
			return pts;
		};
		const getChildCoordinates = (time: number, offset: number, segments: number) => {
			
			let pts = getCoordinates(time, segments);
			for (let i = 4; i < pts.length - 4; i += 2) {
				pts[i] = pts[i] + noise.noise2D((time + offset + i) * 10, 0) * amplitude / 1.5;
			}
			return pts;
		};
		
		context.clearRect(0, 0, canvas.width, canvas.height);
		
		context.beginPath();
		
		context.beginPath();
		context.lineWidth = 3;
		context.strokeStyle = hslToRgb(H, S, colorLChild1);
		
		drawCurve(context, getChildCoordinates(time, .25, 8), 1, false, 15, false);
		context.stroke();
		context.beginPath();
		context.lineWidth = 3;
		context.strokeStyle = hslToRgb(H, S, colorLChild2);
		drawCurve(context, getChildCoordinates(time, 20, 8), 1, false, 15, false);
		context.stroke();
		context.beginPath();
		context.lineWidth = 7;
		context.strokeStyle = hslToRgb(H, S, colorL);
		context.shadowBlur = 50;
		context.shadowColor = hslToRgb(H, S, colorL);
		drawCurve(context, getCoordinates(time, 8), .5, false, 8, false);
		context.stroke();
		context.lineCap = 'round';
		drawImpact(context, getCoordinates(time, 8), flicker, hslToRgb(H, S, colorL));
		
		time += .005;
		flicker += .15;
		
		animationId = requestAnimationFrame(render);
	};
	render();
	
	
};
const cancelRender = () => {
	cancelAnimationFrame(animationId as number);
};
interface LightningStrikeProps {
	children?: React.ReactNode;
}
const LightningStrike: React.FC<LightningStrikeProps> = ({ children }) => {
	// const { windowHeight, windowWidth } = useWindowDimensions();
	const {windowWidth: actualWindowWidth, windowHeight: actualWindowHeight} = useWindowDimensions();
	const windowHeight = actualWindowHeight;
	const windowWidth = actualWindowWidth > theme.breakpoints.sm ? 500 : actualWindowWidth;
	
	const [mouseXY, setMouseXY] = useState({x: 0, y: 0});
	const [clickedZap, setClickedZap] = useState(false);
	const canvasRef = useRef<HTMLCanvasElement>(null);
	
	
	useEffect(() => {
		const canvas = canvasRef.current;
		const context = canvas?.getContext('2d');
		if(canvas && context) {
			canvas.width = windowWidth > theme.breakpoints.sm ? 500 : windowWidth;
			canvas.height = 500;
			clickedZap ? renderStrike(canvas, context, windowWidth, windowHeight, mouseXY, clickedZap) : cancelStrike(canvas, context);
		}
		return () => {
			cancelRender();
		};
	}, [clickedZap, mouseXY, windowHeight, windowWidth]);
	
	const handleClick = (event: React.PointerEvent<HTMLDivElement>) => {
		const node = event.target as HTMLElement
		setClickedZap(prev => !prev);
		const rect = node.getBoundingClientRect();
		setMouseXY({
			x: event.clientX + (windowWidth > theme.breakpoints.sm ? 0 : 250 - (rect.right)),
			y: event.clientY + rect.top,
		});
		node.setPointerCapture(event.pointerId);
	};
	const handleMouseUp = () => {
		setClickedZap(false);
	};
	
	useEffect(() => {
		if (clickedZap) {
			document.addEventListener('pointerup', handleMouseUp);
		} else {
			document.removeEventListener('pointerup', handleMouseUp);
		}
		return () => {
			document.removeEventListener('pointerup', handleMouseUp);
		};
	}, [clickedZap]);
	return (
		<div onPointerUp={handleMouseUp} onPointerDown={handleClick} css={styles.root}>
			<canvas ref={canvasRef} css={clickedZap ? styles.canvas : styles.noCanvas}/>
			{children}
		</div>
	);
};

export default LightningStrike;`,
	magneticBox: `/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect } from 'react';
import { css } from '@emotion/react';
import { theme } from "../theme";

// Define types for props and state
interface MagneticBoxProps {
	rowCount?: number;
	columnCount?: number;
	rowOffset?: number;
	columnOffset?: number;
	height?: number;
	maxLength?: number;
	fade?: boolean;
	noFill?: string;
	fill?: string;
	canvasWidth?: number;
	canvasHeight?: number;
	influenceRadius?: number;
	border?: boolean;
}

interface MousePosition {
	x: number;
	y: number;
}

// Define styles using Emotion's css helper
const styles = {
	root: css\`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        overflow: hidden;
        touch-action: none;
    \`,
	canvas: (border: boolean) => css\`
        border: \${border ? "solid 1px lightgray" : "none"};
    \`,
};

// Utility function to calculate the length of the element based on mouse proximity
const elementLength = (
	distance: number,
	maxLength: number,
	influenceRadius: number
): number => {
	const minLength = 0;
	const offsetLength = ((maxLength * influenceRadius) - distance) * 0.1; // Affects growth radius and speed
	const testLength = Math.max(minLength, offsetLength); // Ensure length is not below min
	return Math.min(testLength, maxLength); // Ensure length does not exceed max
};

const MagneticBox: React.FC<MagneticBoxProps> = (props) => {
	// Initialize mouse position outside canvas
	const [mouseXY, setMouseXY] = useState<MousePosition>({ x: 100000, y: 100000 });
	const canvasRef = useRef<HTMLCanvasElement>(null); // Reference to canvas DOM element
	
	useEffect(() => {
		const canvas = canvasRef.current;
		if (!canvas) return;
		
		// Calculate canvas width and height based on props
		const canvasWidth = props.canvasWidth || (props.columnCount! * props.columnOffset!) || 500;
		const canvasHeight = props.canvasHeight || (props.rowCount! * props.rowOffset!) || 300;
		
		canvas.width = canvasWidth;
		canvas.height = canvasHeight;
		const context = canvas.getContext('2d');
		if (!context) return;
		let requestId: number;
		
		// Function to draw a single element in the grid
		const element = (
			originX: number,
			originY: number,
			noFill: string,
			fill: string,
			x: number,
			y: number,
			height: number,
			maxLength: number,
			fade: boolean,
			influenceRadius: number
		) => {
		 	// Distance from mouse
			const distance = Math.sqrt(Math.pow(x - originX, 2) + Math.pow(y - originY, 2));
			const opacity = fade ? elementLength(distance, maxLength, influenceRadius) / maxLength : 1;
			// Determine length based on proximity
			const length = elementLength(distance, maxLength, influenceRadius);
			
			context.fillStyle = length === 0 ? noFill : fill;
			context.save();
			context.beginPath();
			context.translate(originX, originY);
			context.rotate(Math.atan2(y - originY, x - originX));
			
			// Draw two arcs forming the element
			context.arc(length, 0, height, 1.5 * Math.PI, 0.5 * Math.PI, false);
			context.arc(-length, 0, height, 0.5 * Math.PI, 1.5 * Math.PI, false);
			
			context.closePath();
			context.globalAlpha = opacity;
			context.fill();
			context.restore();
		};
		
		// Function to draw the grid of elements based on row and column count
		const elementArray = (
			rowCount: number,
			columnCount: number,
			rowOffset: number,
			columnOffset: number,
			noFill: string,
			fill: string,
			mouseX: number,
			mouseY: number,
			height: number,
			maxLength: number,
			fade: boolean,
			influenceRadius: number
		) => {
			for (let xx = 0; xx < columnCount; xx++) {
				for (let yy = 0; yy < rowCount; yy++) {
					element(
						xx * columnOffset + columnOffset / 2,
						yy * rowOffset + rowOffset / 2,
						noFill,
						fill,
						mouseX,
						mouseY,
						height,
						maxLength,
						fade,
						influenceRadius
					);
				}
			}
		};
		
		// Render loop
		const render = () => {
			const x = mouseXY.x - canvas.offsetLeft; // Mouse X relative to canvas
			const y = mouseXY.y; // Mouse Y relative to canvas
			
			context.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas for next frame
			
			// Draw the grid of elements
			elementArray(
				props.rowCount || 20,
				props.columnCount || 20,
				props.rowOffset || 30,
				props.columnOffset || 30,
				props.noFill || (props.fill ? props.fill : theme.colors.backZ),
				props.fill || theme.colors.frontZ,
				x,
				y,
				props.height || 2,
				props.maxLength || 5,
				props.fade || false,
				props.influenceRadius || 25
			);
			// Loop rendering using requestAnimationFrame
			requestId = requestAnimationFrame(render);
		};
		
		render();
		
		// Cleanup function to cancel animation on component unmount
		return () => {
			cancelAnimationFrame(requestId);
		};
	}, [
		mouseXY,
		props.rowCount,
		props.columnCount,
		props.rowOffset,
		props.columnOffset,
		props.height,
		props.maxLength,
		props.fade,
		props.noFill,
		props.fill,
		props.canvasWidth,
		props.canvasHeight,
		props.influenceRadius,
	]);
	
	// Handle mouse movement over canvas
	const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
		const { left, top } = event.currentTarget.getBoundingClientRect();
		setMouseXY({ x: event.pageX - left, y: event.pageY - top });
	};
	
	// Reset mouse position when it leaves the canvas
	const handleMouseLeave = () => {
	// Move far away to "deactivate" all elements
		setMouseXY({ x: 10000, y: 100000 });
	};
	
	// Handle pointer events (e.g., for touch input)
	const handlePointerMove = (event: React.PointerEvent<HTMLDivElement>) => {
		const { left, top } = event.currentTarget.getBoundingClientRect();
		setMouseXY({ x: event.pageX - left, y: event.pageY - top });
	};
	
	return (
		<div
			onPointerMove={handlePointerMove}
			onMouseUp={handleMouseLeave}
			onPointerDown={handleMouseMove}
			onPointerUp={handleMouseLeave}
			onMouseMove={handleMouseMove}
			onMouseLeave={handleMouseLeave}
			css="{styles.root}"
		>
			<canvas ref="{canvasRef}" css="{styles.canvas(!!props.border)}" />
		</div>
	);
};

export default MagneticBox;`,
};
