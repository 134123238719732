/** @jsxImportSource @emotion/react */ // Include this in all JSX files using Emotion
import React, { useState, useRef, useEffect } from 'react';
import { css } from '@emotion/react';
import { theme } from "../theme";

// Define types for props and state
interface MagneticBoxProps {
    rowCount?: number;
    columnCount?: number;
    rowOffset?: number;
    columnOffset?: number;
    height?: number;
    maxLength?: number;
    fade?: boolean;
    noFill?: string;
    fill?: string;
    canvasWidth?: number;
    canvasHeight?: number;
    influenceRadius?: number;
    border?: boolean;
}

interface MousePosition {
    x: number;
    y: number;
}

// Define styles using Emotion's `css` helper
const styles = {
    root: css`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        overflow: hidden;
        touch-action: none;
    `,
    canvas: (border: boolean) => css`
        border: ${border ? "solid 1px lightgray" : "none"};
    `,
};

// Utility function to calculate the length of the element based on mouse proximity
const elementLength = (
  distance: number,
  maxLength: number,
  influenceRadius: number
): number => {
    const minLength = 0;
    const offsetLength = ((maxLength * influenceRadius) - distance) * 0.1; // Affects growth radius and speed
    const testLength = Math.max(minLength, offsetLength); // Ensure length is not below min
    return Math.min(testLength, maxLength); // Ensure length does not exceed max
};

const MagneticBox: React.FC<MagneticBoxProps> = (props) => {
    const [mouseXY, setMouseXY] = useState<MousePosition>({ x: 100000, y: 100000 }); // Initialize mouse position outside canvas
    const canvasRef = useRef<HTMLCanvasElement>(null); // Reference to canvas DOM element
    
    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        
        // Calculate canvas width and height based on props
        const canvasWidth = props.canvasWidth || (props.columnCount! * props.columnOffset!) || 500;
        const canvasHeight = props.canvasHeight || (props.rowCount! * props.rowOffset!) || 300;
        
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        const context = canvas.getContext('2d');
        if (!context) return;
        let requestId: number;
        
        // Function to draw a single element in the grid
        const element = (
          originX: number,
          originY: number,
          noFill: string,
          fill: string,
          x: number,
          y: number,
          height: number,
          maxLength: number,
          fade: boolean,
          influenceRadius: number
        ) => {
            const distance = Math.sqrt(Math.pow(x - originX, 2) + Math.pow(y - originY, 2)); // Distance from mouse
            const opacity = fade ? elementLength(distance, maxLength, influenceRadius) / maxLength : 1;
            const length = elementLength(distance, maxLength, influenceRadius); // Determine length based on proximity
            
            context.fillStyle = length === 0 ? noFill : fill;
            context.save();
            context.beginPath();
            context.translate(originX, originY);
            context.rotate(Math.atan2(y - originY, x - originX));
            
            // Draw two arcs forming the element
            context.arc(length, 0, height, 1.5 * Math.PI, 0.5 * Math.PI, false);
            context.arc(-length, 0, height, 0.5 * Math.PI, 1.5 * Math.PI, false);
            
            context.closePath();
            context.globalAlpha = opacity;
            context.fill();
            context.restore();
        };
        
        // Function to draw the grid of elements based on row and column count
        const elementArray = (
          rowCount: number,
          columnCount: number,
          rowOffset: number,
          columnOffset: number,
          noFill: string,
          fill: string,
          mouseX: number,
          mouseY: number,
          height: number,
          maxLength: number,
          fade: boolean,
          influenceRadius: number
        ) => {
            for (let xx = 0; xx < columnCount; xx++) {
                for (let yy = 0; yy < rowCount; yy++) {
                    element(
                      xx * columnOffset + columnOffset / 2,
                      yy * rowOffset + rowOffset / 2,
                      noFill,
                      fill,
                      mouseX,
                      mouseY,
                      height,
                      maxLength,
                      fade,
                      influenceRadius
                    );
                }
            }
        };
        
        // Render loop
        const render = () => {
            const x = mouseXY.x - canvas.offsetLeft; // Mouse X relative to canvas
            const y = mouseXY.y; // Mouse Y relative to canvas
            
            context.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas for next frame
            
            // Draw the grid of elements
            elementArray(
              props.rowCount || 20,
              props.columnCount || 20,
              props.rowOffset || 30,
              props.columnOffset || 30,
              props.noFill || (props.fill ? props.fill : theme.colors.backZ),
              props.fill || theme.colors.frontZ,
              x,
              y,
              props.height || 2,
              props.maxLength || 5,
              props.fade || false,
              props.influenceRadius || 25
            );
            
            requestId = requestAnimationFrame(render); // Loop rendering using requestAnimationFrame
        };
        
        render();
        
        // Cleanup function to cancel animation on component unmount
        return () => {
            cancelAnimationFrame(requestId);
        };
    }, [
        mouseXY,
        props.rowCount,
        props.columnCount,
        props.rowOffset,
        props.columnOffset,
        props.height,
        props.maxLength,
        props.fade,
        props.noFill,
        props.fill,
        props.canvasWidth,
        props.canvasHeight,
        props.influenceRadius,
    ]);
    
    // Handle mouse movement over canvas
    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
        const { left, top } = event.currentTarget.getBoundingClientRect();
        setMouseXY({ x: event.pageX - left, y: event.pageY - top });
    };
    
    // Reset mouse position when it leaves the canvas
    const handleMouseLeave = () => {
        setMouseXY({ x: 10000, y: 100000 }); // Move far away to "deactivate" all elements
    };
    
    // Handle pointer events (e.g., for touch input)
    const handlePointerMove = (event: React.PointerEvent<HTMLDivElement>) => {
        const { left, top } = event.currentTarget.getBoundingClientRect();
        setMouseXY({ x: event.pageX - left, y: event.pageY - top });
    };
    
    return (
      <div
        onPointerMove={handlePointerMove}
        onMouseUp={handleMouseLeave}
        onPointerDown={handleMouseMove}
        onPointerUp={handleMouseLeave}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        css={styles.root}
      >
          <canvas ref={canvasRef} css={styles.canvas(!!props.border)} />
      </div>
    );
};

export default MagneticBox;
