/** @jsxImportSource @emotion/react */
import React, {useState} from 'react';
import {css} from '@emotion/react';
import Slider from '../Slider';
import VerticalSlider from '../VerticalSlider';
import {theme} from '../../theme';

const styles = {
	root: css`
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
	`,
	stage: css`
      perspective: 1000px;
      padding: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 30px;
	`,
	extrusion: css`
      width: 200px;
      height: 200px;
      position: relative;
      transform-style: preserve-3d;
      transform: translateZ(-100px);
      transition: transform 1s;
      padding: 50px;
	
	`,
	plane: css`
      position: absolute;
      width: 150px;
      height: 150px;
      border-radius: 20px;
	`,
	left: (hRotation: number, vRotation: number) => css`
      background-color: ${theme.colors.frontZ};
      transform: rotateY(${hRotation}deg) rotateX(${vRotation}deg) translateZ(-50px);
	`,
	right: (hRotation: number, vRotation: number) => css`
      background-color: ${theme.colors.backZ};
      transform: rotateY(${hRotation}deg) rotateX(${vRotation}deg) translateZ(50px);
	`,
};


const RotationShapes = () => {
	const [hRotation, setHRotation] = useState(0);
	const [vRotation, setVRotation] = useState(0);
	
	const handleChangeHorizontal = (percent: number) => {
		setHRotation(percent * 360 + 180);
	};
	const handleChangeVertical = (percent: number) => {
		setVRotation(percent * 360 + 180);
	};
	
	return (
		<div css={styles.root}>
			<div css={styles.stage}>
				<div css={styles.extrusion}>
					<div css={[styles.plane, styles.left(hRotation, vRotation)]}/>
					<div css={[styles.plane, styles.right(hRotation, vRotation)]}/>
				</div>
				<VerticalSlider initialPosition={100} onChange={handleChangeVertical}/>
			</div>
			<Slider initialPosition={100} onChange={handleChangeHorizontal}/>
		</div>
	);
};

export default RotationShapes;