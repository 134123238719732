/** @jsxImportSource @emotion/react */  //include this in all jsx files
import {css} from '@emotion/react';

import React from 'react';
import GenericIcon, {IconTypes} from './GenericIcon';


const styles = {
  icon: css`
    color: black;
  `,
};

const LeftChevronIcon: React.FC<IconTypes> = props => (
  <GenericIcon variant={props.variant} onClick={props.onClick} icon="chevron_left"
           className={props.className + ' ' + styles.icon}/>
);

export default LeftChevronIcon;