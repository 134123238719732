export const theme = {
  colors: {
    primary: 'red',
    background: '#1d1d1d',
    headerAndBorder: '#374785',
    mid: '#a8d0e6',
    backZ: '#14A098',
    frontZ: '#cb2d6f',
    text: '#fff',
  },
  breakpoints: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
  zIndex: {
    navigationHeader: 999,
    drawer: 998,
  },
  transitions: {
    smoothWithBounce: 'all 200ms cubic-bezier(.3, .7, .4, 1.5)',
    smoothWithBounceSlower: 'all 600ms cubic-bezier(0.5, 0.5, 0.64, 1.41)',
    smoothFast: 'all 150ms'

  }
}


export type ScreenSizeBreakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

