/** @jsxImportSource @emotion/react */        //include this in all jsx files
import {css, SerializedStyles} from '@emotion/react';
import React, {useCallback} from 'react';

const styles = {
  icon: css`
    justify-self: center;
    align-self: center;
    cursor: pointer;
  `,
  iconWrapper: css`
    display: flex;
  `,
};

export const ICON_VARIANTS = {
  outlined: 'outlined',
  rounded: 'rounded',
  sharp: 'sharp',
  twoTone: 'twoTone',
};
export type StyleTypes = SerializedStyles | string;
export interface IconTypes {
  variant?: 'outlined' | 'rounded' | 'sharp' | 'twoTone'
  isSymbol?: boolean
  onClick?: (any: any) => void,
  className?: StyleTypes | string,
}
interface Props extends IconTypes {
  icon: string,
  className?: StyleTypes,
}

const GenericIcon: React.FC<Props> = ({variant, onClick, className, icon, isSymbol = false}) => {

  const variantClass = useCallback(() => {
    const iconOrSymbol = isSymbol ? '-symbols' : '-icons';
    switch (variant) {
      case ICON_VARIANTS.outlined:
        return 'material' + iconOrSymbol + '-outlined';
      case ICON_VARIANTS.rounded:
        return 'material' + iconOrSymbol + '-round';
      case ICON_VARIANTS.sharp:
        return 'material' + iconOrSymbol + '-sharp';
      case ICON_VARIANTS.twoTone:
        return 'material' + iconOrSymbol + '-two-tone';
      default:
        return 'material' + iconOrSymbol + '-outlined';
    }
  }, [variant, isSymbol]);

  return (
    <div css={styles.iconWrapper} onClick={onClick}>
      <span className={variantClass() + ' ' + className} css={styles.icon}>{icon}</span>
    </div>
  );
};
export default GenericIcon;