/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';

const DEFAULT_SLIDER_WIDTH = 200

// Define Emotion CSS styles
const styles = {
    sliderRoot: css`
        position: relative;
        touch-action: none; // Disable default touch behavior
        
        &:active {
            // Add shadow transformation on active
            & > .shadow {
                transform: translateY(-5px) translateX(-12px);
                transition: transform 34ms;
            }
        }
    `,
    root: css`
        justify-content: center;
        width: ${DEFAULT_SLIDER_WIDTH}px;
        height: 10px;
        padding: 20px;
        border-radius: 10px;
        touch-action: none; // Prevent default touch behavior
    `,
    bar: css`
        height: 6px;
        border-radius: 50px;
        width: ${DEFAULT_SLIDER_WIDTH}px;
        background-color: #85aabf;
        position: relative;
    `,
    // Dynamic width for status bar (based on slider position)
    status: (location: number) => css`
        height: 6px;
        border-radius: 50px;
        width: ${location}px;
        background-color: #0e5e8c;
        position: absolute;
        top: 0;
        left: 0;
    `,
    thumb: (location: number) => css`
        height: 20px;
        width: 30px;
        border-radius: 100px;
        left: ${location}px;
        background-color: #158ad0;
        position: absolute;
        top: 0;
        touch-action: none; // Disable default touch behavior
        cursor: grab;
        transform: translateY(-10px) translateX(-12px); // Adjust thumb position
        transition: transform 400ms cubic-bezier(.3, .7, .4, 1);
        
        &:hover {
            // Slight lift on hover
            transform: translateY(-12px) translateX(-12px);
            transition: transform 250ms;
        }
        
        &:active {
            // Adjust thumb for active state
            cursor: grabbing;
            transform: translateY(-8px) translateX(-12px);
            transition: transform 34ms;
        }
    `,
    thumbEdge: (location: number) => css`
        height: 20px;
        width: 30px;
        border-radius: 100px;
        left: ${location}px;
        background-color: #0e5e8c;
        position: absolute;
        top: 0;
        cursor: grab;
        transform: translateY(-7px) translateX(-12px); // Adjust thumb position
    `,
    shadow: (location: number) => css`
        height: 20px;
        width: 30px;
        border-radius: 100px;
        left: ${location}px;
        background: hsl(0deg 0% 0% / 0.25); // Light shadow
        position: absolute;
        will-change: transform;
        top: 0;
        cursor: grab;
        filter: blur(1px);
        transform: translateY(-4px) translateX(-12px); // Adjust shadow position
        transition: transform 400ms cubic-bezier(.3, .7, .4, 1);
    `,
};
interface SliderProps {
    initialPosition?: number; // Starting position of the slider (optional)
    className?: string; // Optional custom class name for styling
    onChange?: (slidePercent: number) => void; // Callback function when slider moves
}

const Slider: React.FC<SliderProps> = ({ initialPosition = 0, className, onChange }) => {
    const [position, setPosition] = useState(initialPosition); // Current slider position
    const [pressed, setPressed] = useState(false); // State to track if slider is being dragged

    // Reference to the slider DOM element
    const sliderRef = useRef<HTMLDivElement | null>(null);
    
    // Function to handle pointer movement
    const onPointerMove = (event: React.PointerEvent<HTMLDivElement>) => {
        if (!pressed || !sliderRef.current) return;
        
        const sliderLeftPosition = sliderRef.current.getBoundingClientRect().left;
        const newPosition = event.clientX - sliderLeftPosition; // Calculate new position
        
        // Update the position if within valid range
        if (newPosition >= 0 && newPosition <= DEFAULT_SLIDER_WIDTH) {
            setPosition(newPosition);
            const newSlidePercent = newPosition / DEFAULT_SLIDER_WIDTH;
            
            // Trigger the onChange callback if provided
            if (onChange) {
                onChange(newSlidePercent);
            }
        }
        
        event.stopPropagation();
        event.preventDefault();
    };
    
    // Function to handle when pointer is released
    const onPointerUp = (event: PointerEvent) => {
        setPressed(false);
        (event.target as HTMLElement).releasePointerCapture(event.pointerId);
        event.stopPropagation();
        event.preventDefault();
    };
    
    // Function to handle pointer press down (starts dragging)
    const onPointerDown = (event: React.PointerEvent) => {
        setPressed(true);
        (event.target as HTMLElement).setPointerCapture(event.pointerId);
        event.stopPropagation();
        event.preventDefault();
    };
    
    // Hook to attach and detach pointer up event listener based on `pressed` state
    useEffect(() => {
        if (pressed) {
            document.addEventListener('pointerup', onPointerUp);
        } else {
            document.removeEventListener('pointerup', onPointerUp);
        }
        
        // Clean up the event listener when component unmounts or `pressed` changes
        return () => {
            document.removeEventListener('pointerup', onPointerUp);
        };
    }, [pressed]);
    
    return (
      <div className={className} css={styles.root} onPointerMove={onPointerMove}>
          <div ref={sliderRef} css={styles.sliderRoot}>
              <div css={styles.bar} /> {/* Slider bar */}
              <div css={styles.status(position)} /> {/* Status bar to show progress */}
              <div id="sliderShadow" className="shadow" css={styles.shadow(position)} /> {/* Shadow effect */}
              <div className="thumbEdge" css={styles.thumbEdge(position)} /> {/* Edge of the thumb */}
              <div className="thumb" onPointerDown={onPointerDown} css={styles.thumb(position)} /> {/* Draggable thumb */}
          </div>
      </div>
    );
};
export default Slider;
