import {RefObject, useCallback, useEffect, useState} from 'react';

// export function useInterval(callback, delay) {
//     const intervalRef = useRef(null);
//     const savedCallback = useRef(callback);
//
//     useEffect(() => {
//         savedCallback.current = callback;
//     }, [callback]);
//
//     useEffect(() => {
//         const tick = () => savedCallback.current();
//         if (typeof delay === 'number') {
//             intervalRef.current = window.setInterval(tick, delay);
//             return () => window.clearInterval(intervalRef.current);
//         }
//     }, [delay]);
//     return intervalRef;
// }

export const debounce = (callback: any, wait: number) => {
  let timeoutId: number | undefined = undefined;
  return (...args: any[]) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
};

// export const throttle = (func, limit) => {
//     let inThrottle;
//     return function () {
//         const args = arguments;
//         const context = this;
//         if (!inThrottle) {
//             func.apply(context, args);
//             inThrottle = true;
//             setTimeout(() => (inThrottle = false), limit);
//         }
//     };
// };

export const useClickOutsideListener = (
  ref: RefObject<HTMLElement>,
  onClickOutside: () => void,
): void => {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (!ref.current?.contains(event.target as Node)) {
        onClickOutside();
      }
    },
    [ref, onClickOutside],
  );
  useEffect(
    () => {
      document.addEventListener('mousedown', handleClickOutside, true);
      return () => document.removeEventListener('mousedown', handleClickOutside, true);
    },
    [handleClickOutside],
  );
};

export const useMountTransition = (isMounted: boolean, unmountDelay: number) => {
  const [hasTransitionedIn, setHasTransitionedIn] = useState(false);

  useEffect(() => {
    let timeoutId: number | undefined;

    if (isMounted && !hasTransitionedIn) {
      setHasTransitionedIn(true);
    } else if (!isMounted && hasTransitionedIn) {
      timeoutId = setTimeout(() => setHasTransitionedIn(false), unmountDelay);
    }

    return () => {
      clearTimeout(timeoutId);
    }
  }, [unmountDelay, isMounted, hasTransitionedIn]);

  return hasTransitionedIn;
}