import React, {useEffect, useState} from 'react';

interface Props {
  className?: string
  text: string,
  typeSpeed: number,
  delay?: number,
}

const Typewriter: React.FC<Props> = ({text = '', typeSpeed = 100, className, delay =0}) => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [delayEnded, setDelayEnded] = useState(delay <= 0)

  useEffect(() => {
    const delayTimeout = setTimeout(() => {
      setDelayEnded(true)
    }, delay);
    return () => clearTimeout(delayTimeout)
  },[delay])

  useEffect(() => {
    if (currentIndex < text.length && delayEnded) {
      const timeout = setTimeout(() => {
        setCurrentText(prevText => prevText + text[currentIndex]);
        setCurrentIndex(prevIndex => prevIndex + 1);
      }, typeSpeed);
      return () => clearTimeout(timeout);
    }
  }, [currentIndex, typeSpeed, text, delayEnded]);

  return <span className={className}>{currentText}</span>;
};

export default Typewriter;