/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

// Define Emotion CSS styles with proper typing for dynamic styles
const styles = {
  wrapper: css`
    position: relative;
    padding: 3px 0 0 0;
    background: transparent;
    border: none;
    cursor: pointer;
    touch-action: none;
    
    &:hover {
      & > .top {
        transform: translateY(-8px);
        transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
      }
      
      & > .shadow {
        transform: translateY(4px);
        transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
      }
    }
    
    &:active {
      & > .top {
        transform: translateY(-2px);
        transition: transform 34ms;
      }
      
      & > .shadow {
        transform: translateY(1px);
        transition: transform 34ms;
      }
    }
  `,
  // The top portion of the button, styled dynamically based on the color
  top: (color: string) => css`
    padding: 15px 40px;
    position: relative;
    top: 0;
    left: 0;
    border-radius: 12px;
    background-color: ${color};
    display: block;
    font-size: 1.25em;
    color: #fff;
    font-weight: 700;
    transform: translateY(-6px);
    will-change: transform;
    transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
  `,
  bottom: (color: string) => css`
    width: 100%;
    height: 100%;
    position: absolute;
    background: ${color};
    top: 0;
    left: 0;
    border-radius: 12px;
  `,
  detail: css`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 12px;
    background: linear-gradient(
      to left,
      hsla(0, 0%, 0%, 0.5) 0%,
      hsla(0, 0%, 0%, 0.3) 8%,
      hsla(0, 0%, 0%, 0.3) 92%,
      hsla(0, 0%, 0%, 0.5) 100%
    );
  `,
  shadow: css`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    transform: translateY(3px);
    border-radius: 12px;
    background: hsl(0deg 0% 0% / 0.25);
    will-change: transform;
    filter: blur(1px);
    transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
  `,
};

// Utility function to check if a given string is a valid CSS color
const hasColor = (color: string): boolean => {
  const styleTest = new Option().style;
  styleTest.color = color;
  return styleTest.color !== '';
};

// Define prop types using TypeScript interfaces
interface AnalogButtonProps {
  color?: string; // Optional color prop, default is "blue" if not provided
  onClick?: () => void; // Optional click handler
  className?: string; // Optional custom class name
  children: React.ReactNode; // The content inside the button (e.g., text or elements)
}

// The AnalogButton component
const AnalogButton: React.FC<AnalogButtonProps> = ({ color = "blue", onClick, className, children }) => {
  const buttonColor = hasColor(color) ? color : "blue"; // Fallback to "blue" if color is invalid
  
  return (
    <button className={className} onPointerUp={onClick} css={styles.wrapper}>
      <div className="shadow" css={styles.shadow} /> {/* Shadow effect */}
      <div css={styles.bottom(buttonColor)} /> {/* Bottom part of the button */}
      <div css={styles.detail} /> {/* Additional detail on the button */}
      <div className="top" css={styles.top(buttonColor)}> {/* Top part of the button */}
        <span>{children}</span> {/* Button content (children) */}
      </div>
    </button>
  );
};

export default AnalogButton;
