/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import ZLogo from './ZLogo';
import React from 'react';
import { theme } from './theme';
import Typewriter from './Utils/Typewriter';
import { useNavigate } from 'react-router';
import { routes } from './Routes';

const styles = {
	content: css`
		display: flex;
		flex-direction: column;
		padding-left: 10px;
		position: relative;
		width: 100%;
	`,
	bigLetters: css`
		font-size: 30px;
		font-family: 'Roboto', sans-serif;
		color: ${theme.colors.text};
		display: flex;
		flex-direction: row;
		gap: 0;
		@media (min-width: ${theme.breakpoints.sm}px) {
			font-size: 50px;
		}
	`,
	buttonContainer: css`
		background: linear-gradient(to right, ${theme.colors.backZ} 4px, transparent 4px) 0 0,
			linear-gradient(to right, ${theme.colors.backZ} 4px, transparent 4px) 0 100%,
			linear-gradient(to left, ${theme.colors.backZ} 4px, transparent 4px) 100% 0,
			linear-gradient(to left, ${theme.colors.backZ} 4px, transparent 4px) 100% 100%,
			linear-gradient(to bottom, ${theme.colors.backZ} 4px, transparent 4px) 0 0,
			linear-gradient(to bottom, ${theme.colors.backZ} 4px, transparent 4px) 100% 0,
			linear-gradient(to top, ${theme.colors.backZ} 4px, transparent 4px) 0 100%,
			linear-gradient(to top, ${theme.colors.backZ} 4px, transparent 4px) 100% 100%;
		background-repeat: no-repeat;
		background-size: 20px 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100px;
		height: 30px;
		margin-top: 20px;
		color: ${theme.colors.frontZ};
		cursor: pointer;
		transition: ${theme.transitions.smoothFast};
		align-self: center;
		outline: none;
		border: none;
		line-height: 15px;

		:hover {
			font-size: 14px;
		}
	`,
};

const Home: React.FC = () => {
	const navigate = useNavigate();

	return (
		<div css={styles.content}>
			<span css={styles.bigLetters}>Hi.</span>
			<div>
				<span css={styles.bigLetters}>
					I'm <ZLogo /> ack,{' '}
				</span>
			</div>
			<span css={styles.bigLetters}>
				R
				<Typewriter
					typeSpeed={100}
					text={'eact Web Developer...'}
					css={styles.bigLetters}
				/>
			</span>
			<br />
			<br />
			<button
				onClick={() => navigate(routes.code)}
				css={styles.buttonContainer}
			>
				Learn more.
			</button>
		</div>
	);
};
export default Home;
