/** @jsxImportSource @emotion/react */ //include this in all jsx files
import React, { useRef, useState } from 'react';
import { css } from '@emotion/react';
import { theme } from './theme';
import ZLogo from './ZLogo';
import { useClickOutsideListener } from './Utils/Hooks';
import { useLocation, useNavigate } from 'react-router';
import { routes } from './Routes';
import { color } from '@uiw/react-codemirror';

const styles = {
	drawerDesktop: css`
		display: flex;
		flex-direction: column;
		min-width: 220px;
		border-right: solid 1px ${theme.colors.frontZ};
		height: 100%;
		box-shadow: 1px 0 13px ${theme.colors.frontZ};
		@media (max-width: ${theme.breakpoints.sm}px) {
			display: none;
		}
	`,
	drawerMobile: css`
		z-index: ${theme.zIndex.navigationHeader};
		display: flex;
		flex-direction: row;
		width: 100%;
		height: 100%;
		position: fixed;
		background-color: ${theme.colors.background};
		top: 0;
		// border-right: solid 1px ${theme.colors.frontZ};
		height: 56px;
		// box-shadow: 1px 0 13px ${theme.colors.frontZ};
		@media (min-width: ${theme.breakpoints.sm}px) {
			display: none;
		}
	`,
	drawerHeader: css`
		border-bottom: solid 1px ${theme.colors.backZ};
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 10px;
		box-shadow: -4px 17px 17px -19px ${theme.colors.backZ};
		@media (max-width: ${theme.breakpoints.sm}px) {
			flex-direction: row;
			width: 100%;
			justify-content: space-between;
		}

		img {
			max-height: 50px;
			max-width: 50px;
		}
	`,
	name: css`
		font-size: 20px;
		font-family: 'Roboto', sans-serif;
		color: ${theme.colors.text};
		display: flex;
		flex-direction: row;
		gap: 0;
	`,
	desktopMenu: css`
		display: flex;
		flex-direction: column;
		height: 100%;
	`,
	isAButton: css`
		cursor: pointer;
	`,
	isNotAButton: css`
		cursor: pointer;
	`,
	drawerContainerMobile: (isOpen: boolean) => css`
		width: ${isOpen ? '200px' : '0px'};
		border-right: ${isOpen ? `solid 1px ${theme.colors.frontZ}` : 'none'};
		height: calc(100% - 56px);
		background-color: ${theme.colors.background};
		margin-top: 56px;
		white-space: nowrap;
		position: absolute;
		box-shadow: 1px 0 13px ${theme.colors.frontZ};
		z-index: ${theme.zIndex.drawer};
		transition: ${theme.transitions.smoothWithBounce};
		visibility: ${isOpen ? 'visible' : 'hidden'};
		display: flex;
		flex-direction: column;
		@media (min-width: ${theme.breakpoints.sm}px) {
			display: none;
		}
	`,
	// 	background-color: #14a098;
	// background-image: linear-gradient(to right, #cb2d6f, 65%, #1d1d1d);
	// font-weight: 700;
	menuItem: (isActive: boolean) => css`
		display: flex;
		width: 100%;
		color: #fff;
		font-size: 14px;
		font-family: 'Roboto Thin', sans-serif;
		border-bottom: solid 1px ${theme.colors.backZ}4d;
		padding: 10px;
		cursor: pointer;
		letter-spacing: 1px;
		align-items: center;
		height: 45px;
		transition: ${theme.transitions.smoothWithBounce};
		${isActive && 'background-image: linear-gradient(to right, #cb2d6f, 65%, #1d1d1d); font-weight: 700;'};
		@media (min-width: ${theme.breakpoints.sm}px) {
			font-size: 14px;
		}

		&:hover {
			${!isActive && 'font-size: 15px;'};
		}
	`,
	iconList: css`
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 8px;
	`,
	socialMediaIcon: css`
		height: 40px;
		cursor: pointer;
	`,
	buttonContainer: css`
		background: linear-gradient(to right, ${theme.colors.backZ} 4px, transparent 4px) 0 0,
			linear-gradient(to right, ${theme.colors.backZ} 4px, transparent 4px) 0 100%,
			linear-gradient(to left, ${theme.colors.backZ} 4px, transparent 4px) 100% 0,
			linear-gradient(to left, ${theme.colors.backZ} 4px, transparent 4px) 100% 100%,
			linear-gradient(to bottom, ${theme.colors.backZ} 4px, transparent 4px) 0 0,
			linear-gradient(to bottom, ${theme.colors.backZ} 4px, transparent 4px) 100% 0,
			linear-gradient(to top, ${theme.colors.backZ} 4px, transparent 4px) 0 100%,
			linear-gradient(to top, ${theme.colors.backZ} 4px, transparent 4px) 100% 100%;
		background-repeat: no-repeat;
		background-size: 20px 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 150px;
		height: 30px;
		margin-top: 20px;
		color: ${theme.colors.frontZ};
		cursor: pointer;
		transition: ${theme.transitions.smoothFast};
		align-self: center;
		outline: none;
		border: none;

		:hover {
			font-size: 14px;
		}
	`,
	bottomDrawer: css`
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin-top: auto;
		margin-bottom: 20px;
	`,
};

const menuItems: { text: string; path: string }[] = [
	{
		text: 'Home',
		path: routes.home,
	},
	{
		text: 'Sample Code',
		path: routes.code,
	},
	{
		text: 'Champions Keep Score',
		path: routes.currentProject,
	},
	{
		text: 'Career',
		path: routes.aboutMe,
	},
];

const Drawer = () => {
	const [isOpen, setIsOpen] = useState(false);
	const drawerRef = useRef<HTMLDivElement>(null);
	const location = useLocation();
	const currentPath = location.pathname;

	const handleOpenDrawer = () => {
		setIsOpen(true);
	};

	const closeMobileDrawer = () => {
		isOpen && setIsOpen(false);
	};

	useClickOutsideListener(drawerRef, closeMobileDrawer);
	const navigate = useNavigate();
	const handleMenuClick = (path: string) => {
		navigate(path);
		setIsOpen(false);
	};
	const renderMenu = () => {
		return menuItems.map((item) => {
			const isActive = item.path === currentPath;

			return (
				<div
					key={item.path}
					css={styles.menuItem(isActive)}
					onClick={() => handleMenuClick(item.path)}
				>
					<span>{item.text}</span>
				</div>
			);
		});
	};

	return (
		<>
			<div css={styles.drawerDesktop}>
				<div css={styles.drawerHeader}>
					<ZLogo />
					<span css={styles.name}>Zack Hulett</span>
				</div>
				<div css={styles.desktopMenu}>
					{renderMenu()}
					<div css={styles.bottomDrawer}>
						<a
							href='/resume.pdf'
							download
							css={styles.buttonContainer}
						>
							Download Resume
						</a>
						<div css={styles.iconList}>
							<a href='https://www.linkedin.com/in/zacharyhulett'>
								<img
									src='/LI-In-Bug.png'
									css={styles.socialMediaIcon}
									alt={'LinkedIn logo'}
								/>
							</a>
							<a href='https://www.instagram.com/studentofbjj/'>
								<img
									src='/instagram.svg'
									alt={'Instagram logo'}
									css={[
										styles.socialMediaIcon,
										css`
											padding-right: 4px;
										`,
									]}
								/>
							</a>
							<a href='https://github.com/zulufoxtrothotel'>
								<img
									src='/github-mark-white.png'
									alt={'Github logo'}
									css={styles.socialMediaIcon}
								/>
							</a>
						</div>
					</div>
				</div>
			</div>

			<div css={styles.drawerMobile}>
				<div css={styles.drawerHeader}>
					{isOpen ? (
						<ZLogo />
					) : (
						<ZLogo
							css={styles.isAButton}
							onClick={handleOpenDrawer}
						/>
					)}
					<span css={styles.name}>Zack Hulett</span>
					<img
						src={'/avatar.png'}
						alt={"Zack Hulett's avatar"}
					/>
				</div>
			</div>
			<div
				ref={drawerRef}
				css={styles.drawerContainerMobile(isOpen)}
			>
				{renderMenu()}
				<div css={styles.bottomDrawer}>
					<a
						href='/resume.pdf'
						download
						css={styles.buttonContainer}
					>
						Download Resume
					</a>
					<div css={styles.iconList}>
						<a href='https://www.linkedin.com/in/zacharyhulett'>
							<img
								src='/LI-In-Bug.png'
								css={styles.socialMediaIcon}
								alt={'LinkedIn logo'}
							/>
						</a>
						<a href='https://www.instagram.com/studentofbjj/'>
							<img
								src='/instagram.svg'
								alt={'Instagram logo'}
								css={[
									styles.socialMediaIcon,
									css`
										padding-right: 4px;
									`,
								]}
							/>
						</a>
						<a href='https://github.com/zulufoxtrothotel'>
							<img
								src='/github-mark-white.png'
								alt={'Github logo'}
								css={styles.socialMediaIcon}
							/>
						</a>
					</div>
				</div>
			</div>
		</>
	);
};

export default Drawer;
