/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';

const DEFAULT_SLIDER_HEIGHT = 200

// Define Emotion CSS styles with proper typing for dynamic styles
const styles = {
    sliderRoot: css`
        position: relative;
        
        &:active {
            & > .shadow {
                transform: translateX(-8px) translateY(-9px);
                transition: transform 34ms;
            }
        }
    `,
    root: css`
        justify-content: center;
        padding: 20px;
        border-radius: 10px;
        height: ${DEFAULT_SLIDER_HEIGHT}px;
        touch-action: none; // Disable default touch behavior
    `,
    bar: css`
        width: 6px;
        border-radius: 50px;
        height: ${DEFAULT_SLIDER_HEIGHT}px;
        background-color: #85AABF;
        position: relative;
    `,
    // Status bar changes dynamically based on the slider position
    status: (location: number) => css`
        width: 6px;
        border-radius: 50px;
        height: ${location}px;
        background-color: #0E5E8C;
        position: absolute;
        top: 0;
        left: 0;
    `,
    thumb: (location: number) => css`
        width: 20px;
        height: 30px;
        border-radius: 100px;
        top: ${location}px;
        background-color: #158AD0;
        position: absolute;
        left: 0;
        touch-action: none; // Disable default touch behavior
        cursor: grab;
        transform: translateX(-8px) translateY(-17px); // Adjust thumb position
        transition: transform 400ms cubic-bezier(.3, .7, .4, 1);
        
        &:hover {
            transform: translateX(-8px) translateY(-19px);
            transition: transform 250ms;
        }
        
        &:active {
            cursor: grabbing;
            transform: translateX(-8px) translateY(-15px);
            transition: transform 34ms;
        }
    `,
    thumbEdge: (location: number) => css`
        width: 20px;
        height: 30px;
        border-radius: 100px;
        top: ${location}px;
        background-color: #0E5E8C;
        position: absolute;
        left: 0;
        cursor: grab;
        transform: translateX(-8px) translateY(-12px); // Adjust thumb edge position
    `,
    shadow: (location: number) => css`
        width: 20px;
        height: 30px;
        border-radius: 100px;
        top: ${location}px;
        background: hsl(0deg 0% 0% / 0.25);
        position: absolute;
        will-change: transform;
        left: 0;
        cursor: grab;
        filter: blur(1px);
        transform: translateX(-8px) translateY(-8px); // Adjust shadow position
        transition: transform 400ms cubic-bezier(.3, .7, .4, 1);
    `,
};

// Define prop types using TypeScript interfaces
interface VerticalSliderProps {
    initialPosition?: number; // Optional initial position, default is 100
    className?: string; // Optional custom class name
    onChange?: (slidePercent: number) => void; // Callback function triggered on slide change
}

// The VerticalSlider component
const VerticalSlider: React.FC<VerticalSliderProps> = ({ initialPosition = 100, className, onChange }) => {
    const [position, setPosition] = useState<number>(initialPosition); // Current position of the slider
    const [pressed, setPressed] = useState<boolean>(false); // Track if the thumb is being dragged
    const [slidePercent, setSlidePercent] = useState<number>(initialPosition / 200); // Slider percentage
    const sliderRef = useRef<HTMLDivElement | null>(null); // Reference to the slider element
    
    // Handle pointer movement
    const onPointerMove = (event: React.PointerEvent<HTMLDivElement>) => {
        if (!pressed || !sliderRef.current) return;
        
        const sliderTopPosition = sliderRef.current.getBoundingClientRect().top;
        const newPosition = event.clientY - sliderTopPosition;
        
        // Check if the new position is within bounds and update accordingly
        if (newPosition >= 0 && newPosition <= DEFAULT_SLIDER_HEIGHT) {
            setPosition(newPosition);
            const newSlidePercent = newPosition / DEFAULT_SLIDER_HEIGHT;
            setSlidePercent(newSlidePercent);
            
            // Trigger the onChange callback if provided
            if (onChange) {
                onChange(newSlidePercent);
            }
        }
        
        event.stopPropagation();
        event.preventDefault();
    };
    
    // Handle pointer release
    const onPointerUp = (event: PointerEvent) => {
        setPressed(false);
        (event.target as HTMLElement).releasePointerCapture(event.pointerId);
        event.stopPropagation();
        event.preventDefault();
    };
    
    // Handle pointer down (start dragging)
    const onPointerDown = (event: React.PointerEvent<HTMLDivElement>) => {
        setPressed(true);
        (event.target as HTMLElement).setPointerCapture(event.pointerId);
        event.stopPropagation();
        event.preventDefault();
    };
    
    // Effect to attach and detach pointer up event listener based on pressed state
    useEffect(() => {
        if (pressed) {
            document.addEventListener('pointerup', onPointerUp);
        } else {
            document.removeEventListener('pointerup', onPointerUp);
        }
        
        // Cleanup event listener when component unmounts or `pressed` changes
        return () => {
            document.removeEventListener('pointerup', onPointerUp);
        };
    }, [pressed]);
    

    return (
      <div className={className} css={styles.root} onPointerMove={onPointerMove}>
          <div ref={sliderRef} css={styles.sliderRoot}>
              <div css={styles.bar} /> {/* Slider bar */}
              <div css={styles.status(position)} /> {/* Status bar */}
              <div id="sliderShadow" className="shadow" css={styles.shadow(position)} /> {/* Shadow effect */}
              <div className="thumbEdge" css={styles.thumbEdge(position)} /> {/* Thumb edge */}
              <div className="thumb" onPointerDown={onPointerDown} css={styles.thumb(position)} /> {/* Thumb (draggable) */}
          </div>
      </div>
    );
};

export default VerticalSlider;
